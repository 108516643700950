import { createContext, useState, ReactNode } from 'react';
import { ResSummaryDrawerContextProps } from './ResSummaryDrawerContextTypes';
import { useMediaQuery } from '@mui/system';

export const ResSummaryDrawerContext = createContext<ResSummaryDrawerContextProps>({
  openDrawer: false,
  setOpenDrawer: () => undefined,
});
export const ResSummaryDrawerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const isMobileOrTablet = useMediaQuery('(max-width:800px)');
  const [openDrawer, setOpenDrawer] = useState(!isMobileOrTablet);

  return (
    <ResSummaryDrawerContext.Provider value={{ openDrawer, setOpenDrawer }}>
      {children}
    </ResSummaryDrawerContext.Provider>
  );
};
