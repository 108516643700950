import { ReservationSummaryCard } from 'components/resSummary/ReservationSummaryCard';
import CardContent from '@mui/material/CardContent';
import { Grid } from '@mui/material';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { Body1 } from '@ehi/ui';
import { parseUrn } from 'utils/urnUtils';
import { useTranslations } from 'components/shared/i18n';
import { useAppSelector } from 'redux/hooks';
import { selectVehicleClassSelection } from 'redux/selectors/bookingEditor';
import { useVehicleContentQuery } from 'services/vehicleContent/vehicleContentQueries';
import { RouterPaths } from 'app/router/RouterPaths';
import { mapLoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewUtils';
import { getCarClassAndDescription } from 'utils/vehicleUtils';

export const VehicleSummary = () => {
  const { t } = useTranslations();
  const vehicleClassSelection = useAppSelector(selectVehicleClassSelection);
  const { data, isFetching, isError } = useVehicleContentQuery();
  const vehicleClass = getCarClassAndDescription(parseUrn(vehicleClassSelection?.preferred), data);

  return (
    <ReservationSummaryCard
      title={t('reservationSummary.vehicle')}
      loading={mapLoadingState(isFetching, isError)}
      path={RouterPaths.Vehicle}
      data-testid='vehicleSummaryCard'>
      <CardContent data-testid='vehicleSummaryContent'>
        <Grid container direction={'column'}>
          <Grid item xs data-testid='vehicleSummaryVehicleClass'>
            <Caption2>{t('vehicle.vehicleClass')}</Caption2>
            <Body1 bold data-testid='vehicleClassValue'>
              {vehicleClass}
            </Body1>
          </Grid>
        </Grid>
      </CardContent>
    </ReservationSummaryCard>
  );
};
