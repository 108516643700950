import { Fragment } from 'react';
import { useLocale, useTranslations } from 'components/shared/i18n';
import { formatCurrency } from 'utils/currencyUtils';
import { RateCardType } from './VehicleTypes';
import { PeriodicVehicleRate, PotentiallyAdjustableAmount } from 'services/booking/bookingTypes';
import { Body2 } from '@ehi/ui';
import { TranslationKey } from 'components/shared/i18n/i18n';

export const RateLabel = ({ amount, currency, period }: RateCardType) => {
  const { locale } = useLocale();
  return (
    <Body2 bold data-testid={'rateLabelText'}>
      {formatCurrency(amount, currency, locale) + period}
    </Body2>
  );
};

type RateCardProps = {
  rate: PeriodicVehicleRate;
};
export const RateCard = ({ rate }: RateCardProps) => {
  const { t } = useTranslations();

  const costPerHour = rate?.costPerHour;
  const costPerDay = rate?.costPerDay || rate?.extraTimeRate?.costPerDay;
  const costPerWeek = rate?.costPerWeek;
  const costPerMonth = rate?.costPerMonth;

  const periodicVehicleRates = (
    [
      { period: 'rates.perHour', rate: costPerHour },
      { period: 'rates.perDay', rate: costPerDay },
      { period: 'rates.perWeek', rate: costPerWeek },
      { period: 'rates.perMonth', rate: costPerMonth },
    ] as Array<{
      period: TranslationKey;
      rate: PotentiallyAdjustableAmount | undefined;
    }>
  )?.filter((periodicVehicleRate) => periodicVehicleRate.rate !== undefined);

  return (
    <div style={{ display: 'flex' }}>
      {periodicVehicleRates?.map((periodicVehicleRate, index) => {
        return (
          <Fragment key={index}>
            <RateLabel
              key={index}
              amount={periodicVehicleRate.rate?.amount ?? 0}
              currency={periodicVehicleRate.rate?.currencyCode ?? ''}
              period={t(periodicVehicleRate.period)}
            />
            {index < periodicVehicleRates.length - 1 && <span>,&nbsp;</span>}
          </Fragment>
        );
      })}
    </div>
  );
};
