import { useNavigate } from 'react-router-dom';
import { AccessDeniedError } from 'components/setLocation/AccessDeniedError';
import { LocationPageCard, StyledContainer, StyledSetLocation } from 'components/setLocation/SetLocation.styles';
import { RouterPaths } from 'app/router/RouterPaths';

export const AccessDeniedErrorPage = () => {
  const navigate = useNavigate();
  return (
    <StyledContainer data-testid='AccessDeniedErrorPage'>
      <StyledSetLocation>
        <LocationPageCard>
          <AccessDeniedError handleClose={() => navigate(RouterPaths.SetLocation)} />
        </LocationPageCard>
      </StyledSetLocation>
    </StyledContainer>
  );
};
