import { useForm } from 'react-hook-form';
import { SavingFormProvider } from 'context/saveAction/SavingFormProvider';
import { ProtectionsList } from './ProtectionsList';
import { useSaveProtections } from './useSaveProtections';
import { EmptyView } from './EmptyView';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { ProtectionFormModel } from 'components/flexFlow/peo/peoTypes';
import { protectionsInitialValues } from 'utils/peoUtils';

export const ProtectionsTab = () => {
  const { saveProtectionAndRefreshEditor, selectedProtections, availableProtections } = useSaveProtections();
  const initialValues: ProtectionFormModel = protectionsInitialValues(selectedProtections, availableProtections);
  const formMethods = useForm({
    defaultValues: initialValues,
  });

  const handleSubmit = async (protectionModal: ProtectionFormModel) => {
    return saveProtectionAndRefreshEditor(protectionModal);
  };

  return (
    <SavingFormProvider {...formMethods} submitFn={handleSubmit}>
      {initialValues?.items?.length > 0 ? <ProtectionsList /> : <EmptyView />}
      <ProgressOverlay inProgress={formMethods.formState.isSubmitting} />
    </SavingFormProvider>
  );
};
