import { FC, useCallback, useState } from 'react';
import { AppBar, Box } from '@mui/material';
import { DriverSearchForm } from 'components/flexFlow/driver/driverSearch/DriverSearchForm';
import { useRenterProfileSearch } from 'components/flexFlow/driver/driverSearch/useRenterProfileSearch';
import { DriverSearchValues } from './DriverSearchTypes';
import { MarginWrapper, StyledList } from 'components/shared/ui/styles/Global.styles';
import { DividerWithMargin } from 'components/shared/ui/styles/Divider.styles';
import DriverSearchResult from './DriverSearchResult';
import { SearchResult } from 'services/renter/driverProfile/driverProfileTypes';
import { NoResultsView } from 'components/shared/ui/noResultsView/NoResultsView';
import { useTranslations } from 'components/shared/i18n';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { RateSource, Renter } from 'services/booking/bookingTypes';
import {
  associateRenterToReservationEditor,
  modifyRateSource,
  updateAdditionalDrivers,
} from 'services/booking/bookingService';
import { loadCounterCookie, loadEhiLocationCookie } from '@ehi/location';
import { useAppSelector } from 'redux/hooks';
import {
  selectAdditionalDrivers,
  selectBookingEditorId,
  selectBrand,
  selectContact,
  selectDriverProfileRenter,
} from 'redux/selectors/bookingEditor';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { useAlert } from 'components/shared/alert/AlertContext';
import { logError } from 'components/shared/logger/splunkLogger';
import { TransactionTypes } from 'utils/routing/TransactionTypes';
import { ErrorSeverity } from '@ehi/analytics';
import { ResponseMessage } from 'services/types/ResponseMessageTypes';
import { usePhoneTypesQuery } from 'services/renter/renterReferenceQueries';
import { calculateAge, toDateTimeString, YEAR_MONTH_DAY_FORMAT } from 'utils/dateUtils';
import { DriverData } from 'components/shared/uiModels/driver/driverDataTypes';
import { ConfirmationDialog } from 'components/flexFlow/driver/editDriver/ConfirmationDialog';
import { ADD, parseContactName, REMOVE } from 'components/flexFlow/driver/driverForm/driverFormUtils';
import { ReservationAccount } from 'services/businessAccount/businessAccountTypes';
import { useAccountDetails } from 'services/businessAccount/useAccountDetails';
import { Body2 } from '@ehi/ui';
import { prioritizeByBrand } from './driverSearchUtils';
import {
  addAdditionalDriverError,
  addDriverError,
  associateRenterToReservationError,
  BOOK2077,
  checkLoyaltyDriverWithRateSource,
  createRequestBody,
  CURRENT,
  DRIVER_PROFILE,
  LOYALTY,
  NEGOTIATED,
  PRIMARY_DRIVER,
  RATE_SOURCE,
} from 'components/flexFlow/driver/driverSearch/driverSearchUtils';
import { parseUrn } from 'utils/urnUtils';
import { Trans } from 'react-i18next';
import { StyledLink } from 'components/shared/ui/noResultsView/NoResultsViewStyles';

export type DriverSearchProps = {
  navigateToDriverForm: (driver: DriverData | undefined) => void;
  onClose: () => void;
  isUpdatingPrimaryDriver?: boolean;
};

interface DriverProfileData {
  driverData: SearchResult;
  accountDetails: ReservationAccount | null;
  updateRateSource: boolean;
}

export const DriverSearch: FC<DriverSearchProps> = ({
  navigateToDriverForm,
  onClose,
  isUpdatingPrimaryDriver = false,
}) => {
  const { t } = useTranslations();
  const { search } = useRenterProfileSearch();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const { showAlert } = useAlert();
  const cookieLocation = loadEhiLocationCookie();
  const counterCookie = loadCounterCookie();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const driverProfileRenter = useAppSelector(selectDriverProfileRenter);
  const additionalDrivers = useAppSelector(selectAdditionalDrivers);
  const contact = useAppSelector(selectContact);
  const [loading, setLoading] = useState<boolean>(false);
  const [results, setResults] = useState<SearchResult[] | undefined>();
  const [driverData, setDriverData] = useState<DriverData | undefined>(undefined);
  const [dialogType, setDialogType] = useState<null | typeof PRIMARY_DRIVER | typeof RATE_SOURCE>(null);
  const [driverProfile, setDriverProfile] = useState<DriverProfileData | undefined>(undefined);
  const { data: phoneTypeDomain, isFetching: isPhoneDomainLoading } = usePhoneTypesQuery();
  const selectedBrand = useAppSelector(selectBrand);
  const { rateSourceInfo } = useAccountDetails();

  const handleError = async (errors: ResponseMessage[] | undefined) => {
    if (errors) {
      setLoading(false);
      await showAlert({
        variant: 'error',
        description: `${addDriverError}: ${errors?.[0]?.localizedMessage || ''}`,
      });
    } else {
      onClose();
    }
  };

  const handleLogError = (error: unknown, message: string) => {
    logError({
      error: {
        message: message,
        supportInformation: {
          transactionType: TransactionTypes.CreateFullRes,
          location: cookieLocation,
          counter: counterCookie?.counterId,
          serviceError: error,
        },
      },
      severity: ErrorSeverity.Fatal,
    });
    setLoading(false);
  };

  const searchForRenter = useCallback(
    async (values: DriverSearchValues): Promise<SearchResult[] | undefined> => {
      const validateDrivers = (urn: string) => {
        const prevDrivers = [...(driverProfileRenter ? [driverProfileRenter] : []), ...(additionalDrivers || [])];
        return prevDrivers.some(({ profile }) => profile === urn);
      };

      setLoading(true);
      return search(values)
        .then(async (results) => {
          const sortedResults = prioritizeByBrand(results, parseUrn(selectedBrand));
          const duplicateDriver = sortedResults.some((result) => result.urn && validateDrivers(result.urn));
          if (duplicateDriver) {
            await showAlert({
              variant: 'error',
              description: `${t('driverSearch.driverAlreadyExists')}`,
            });
            return;
          }

          setResults(sortedResults);
          return sortedResults;
        })
        .catch(() => {
          setResults([]);
          return [];
        })
        .finally(() => {
          if (values) {
            const dobString = toDateTimeString(values.dateOfBirth, YEAR_MONTH_DAY_FORMAT) ?? undefined;
            setDriverData({
              phoneNumbers: [{ number: values.phoneNumber }],
              firstName: values.firstName,
              lastName: values.lastName,
              age: dobString ? calculateAge(dobString) : undefined,
            });
          }
          setLoading(false);
        });
    },
    [search, driverProfileRenter, additionalDrivers, selectedBrand, showAlert, t]
  );

  const handleAddDriver = async (driverData: SearchResult) => {
    setLoading(true);
    const accountDetails = await checkLoyaltyDriverWithRateSource(driverData, handleLogError, setLoading);

    if (isUpdatingPrimaryDriver) {
      setLoading(false);
      setDriverProfile({
        driverData: driverData,
        accountDetails: accountDetails,
        updateRateSource: false,
      });
      determineDriverAction(accountDetails);
      return;
    }

    if (!driverProfileRenter) {
      const membershipProgram = driverData?.loyaltyMembership?.loyaltyProgram;
      try {
        const requestBody = createRequestBody(DRIVER_PROFILE, driverData?.urn, membershipProgram);
        const rateSourceRequestBody = createRequestBody(NEGOTIATED, undefined, undefined, accountDetails?.urn);

        const { errors } = await updateAndRefresh(async () => {
          await associateRenterToReservationEditor(bookingEditorId, requestBody as Renter);
          if (accountDetails) await modifyRateSource(bookingEditorId, rateSourceRequestBody as RateSource);
        });
        await handleError(errors);
      } catch (error) {
        handleLogError(error, associateRenterToReservationError);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const currentAdditionalDrivers = additionalDrivers && additionalDrivers.length > 0 ? additionalDrivers : [];
        const requestBody = [
          ...currentAdditionalDrivers,
          {
            name: { given: driverData.name?.givenName ?? '', surname: driverData.name?.surname ?? '' },
            profile: driverData.urn,
          },
        ];
        const { errors } = await updateAndRefresh(() => updateAdditionalDrivers(bookingEditorId, requestBody));
        await handleError(errors);
      } catch (error) {
        handleLogError(error, addAdditionalDriverError);
      } finally {
        setLoading(false);
      }
    }
    setLoading(false);
  };

  const updateWithErrorHandling = async (updateFunc: () => Promise<any>, errorMessage: string) => {
    setLoading(true);
    try {
      const { errors } = await updateAndRefresh(
        async () => {
          await updateFunc();

          if (driverProfile?.updateRateSource && driverProfile.accountDetails) {
            const rateSourceRequestBody = createRequestBody(
              NEGOTIATED,
              undefined,
              undefined,
              driverProfile.accountDetails.urn
            );
            await modifyRateSource(bookingEditorId, rateSourceRequestBody as RateSource);
          }
        },
        driverProfile?.updateRateSource && driverProfile.accountDetails ? [BOOK2077] : []
      );
      await handleError(errors);
    } catch (error) {
      handleLogError(error, errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmChangePrimaryDriver = async (selection: string) => {
    setDialogType(null);
    setLoading(true);

    if (selection === REMOVE && driverProfile?.driverData) {
      const primaryDriverRequestBody = createRequestBody(
        DRIVER_PROFILE,
        driverProfile.driverData?.urn,
        driverProfile.driverData.loyaltyMembership?.loyaltyProgram
      );
      await updateWithErrorHandling(
        () => associateRenterToReservationEditor(bookingEditorId, primaryDriverRequestBody as Renter),
        associateRenterToReservationError
      );
    } else {
      if (driverProfileRenter && driverProfile?.driverData) {
        const { given, surname } = parseContactName(contact?.name ?? '');

        const primaryDriverRequestBody = createRequestBody(
          DRIVER_PROFILE,
          driverProfile.driverData.urn,
          driverProfile.driverData.loyaltyMembership?.loyaltyProgram
        );

        await updateWithErrorHandling(
          () => associateRenterToReservationEditor(bookingEditorId, primaryDriverRequestBody as Renter),
          associateRenterToReservationError
        );

        const currentAdditionalDrivers = additionalDrivers?.length ? additionalDrivers : [];
        const additionalDriverRequestBody = [
          ...currentAdditionalDrivers,
          {
            name: { given, surname },
            profile: driverProfileRenter?.profile,
          },
        ];

        await updateWithErrorHandling(
          () => updateAdditionalDrivers(bookingEditorId, additionalDriverRequestBody),
          addAdditionalDriverError
        );
      }
    }
  };

  const handleCancelChangePrimaryDriver = () => {
    setDialogType(null);
  };

  const determineDriverAction = (accountDetails: ReservationAccount | null) => {
    if ((rateSourceInfo && accountDetails) || (!rateSourceInfo && accountDetails)) {
      setDialogType(RATE_SOURCE);
    } else if (!accountDetails) {
      setDialogType(PRIMARY_DRIVER);
    }
  };

  const handleModifyRateSelection = async (selection: string) => {
    setLoading(true);
    setDialogType(null);

    if (selection === LOYALTY) {
      setDriverProfile((prevState) => {
        if (driverProfile?.driverData && driverProfile.accountDetails) {
          return {
            ...driverProfile,
            updateRateSource: true,
          };
        }
        return prevState;
      });

      setDialogType(PRIMARY_DRIVER);
    } else {
      setDialogType(PRIMARY_DRIVER);
    }
    setLoading(false);
  };

  const dialogConfig =
    dialogType === RATE_SOURCE
      ? {
          title: t('driver.confirmRateSource'),
          description: t('driver.confirmRateSourcePrompt'),
          options: [
            { label: t('driver.keepCurrentRateSource'), value: CURRENT },
            { label: t('driver.useLoyaltyRateSource'), value: LOYALTY },
          ],
          onConfirm: handleModifyRateSelection,
          onCancel: handleCancelChangePrimaryDriver,
          defaultSelection: CURRENT,
        }
      : {
          title: t('driver.changePrimaryDriver'),
          description: t('driver.additionalDrivers.changePrimaryPrompt'),
          options: [
            { label: t('driver.additionalDrivers.makeAdditionalDriver'), value: ADD },
            { label: t('driver.removeFromReservation'), value: REMOVE },
          ],
          onConfirm: handleConfirmChangePrimaryDriver,
          onCancel: handleCancelChangePrimaryDriver,
          defaultSelection: undefined,
        };

  return (
    <>
      <AppBar position='sticky' color='inherit' style={{ boxShadow: 'none' }}>
        <DriverSearchForm search={searchForRenter} />
      </AppBar>
      <MarginWrapper>
        {results && results.length > 0 && (
          <StyledList>
            <Body2 data-testid='result-header'>{`${results.length} ${t('driverSearch.results')}`} </Body2>
            <DividerWithMargin />
            {results.map((renter, index: number) => (
              <DriverSearchResult
                onClose={onClose}
                testId={`driverSearchResult-${index}`}
                searchResult={renter}
                key={index}
                onAddDriver={(renter: SearchResult) => handleAddDriver(renter)}
                phoneTypeDomain={phoneTypeDomain}
              />
            ))}
          </StyledList>
        )}
        {results && results.length === 0 && (
          <Box paddingTop={12}>
            <NoResultsView
              noResultsTitle='driverSearch.noResultsTitle'
              noResultsDescription={
                <Trans
                  i18nKey={'driverSearch.noResultsAddInfo'}
                  components={{
                    Link: (
                      <StyledLink data-testid={'navigationLink'} onClick={() => navigateToDriverForm(driverData)} />
                    ),
                  }}
                />
              }
              isBackgroundColor={false}
            />
          </Box>
        )}
      </MarginWrapper>
      <ProgressOverlay inProgress={loading || isPhoneDomainLoading} />

      <ConfirmationDialog
        open={dialogType !== null}
        onConfirm={dialogConfig.onConfirm}
        onCancel={dialogConfig.onCancel}
        title={dialogConfig.title}
        description={dialogConfig.description}
        options={dialogConfig.options}
        defaultSelection={dialogConfig.defaultSelection}
      />
    </>
  );
};
