import { SummaryCardStyled } from 'components/resSummary/ReservationSummaryCard.styles';
import { LoadableView } from 'components/shared/ui/spinner/loadableView/LoadableView';
import { CardContentWithoutHeader } from 'components/shared/ui/card/Card.styles';
import { Placeholder } from 'components/shared/Placeholder';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { useContext } from 'react';
import { ResSummaryDrawerContext } from 'context/resSummary/ResSummaryDrawerContext';
import { ReservationSummaryCardProps } from './ReservationSummaryTypes';
import NetworkError from 'components/shared/errors/NetworkError';
import { LoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewTypes';

export const ReservationSummaryCard = ({ loading, children, ...rest }: ReservationSummaryCardProps) => {
  const { setOpenDrawer } = useContext(ResSummaryDrawerContext);
  const navigate = useNavigate();
  const isMobileOrTablet = useMediaQuery('max-width(800px)');
  if (loading === LoadingState.LOADING) {
    rest.title = undefined;
  }
  const handleClick = () => {
    if (isMobileOrTablet) {
      setOpenDrawer(false);
    }
    const fullPath = rest.params ? `${rest.path}?${rest.params}` : rest.path;
    navigate(fullPath);
  };

  return (
    <SummaryCardStyled variant={'outlined'} {...rest} onClick={handleClick}>
      <LoadableView
        state={loading}
        errorComponent={<NetworkError />}
        loadingComponent={
          <CardContentWithoutHeader data-testid='loadingContent'>
            <Placeholder rows={3} columns={2} withHeader />
          </CardContentWithoutHeader>
        }>
        {children}
      </LoadableView>
    </SummaryCardStyled>
  );
};
