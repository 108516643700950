import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useLocale } from 'components/shared/i18n/index';
import { DatePickerLocalizationProviderProps } from './LocalesTypes';

export const DatePickerLocalizationProvider = ({ children }: DatePickerLocalizationProviderProps) => {
  const { locale } = useLocale();

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={locale}>
      {children}
    </LocalizationProvider>
  );
};
