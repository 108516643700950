import { FullScreenSpinnerContainer } from 'components/shared/ui/spinner/spinner.styles';
import { CircularProgress } from '@mui/material';

export const FullScreenSpinner = () => {
  return (
    <FullScreenSpinnerContainer>
      <CircularProgress data-testid={'loadingCircle'} />
    </FullScreenSpinnerContainer>
  );
};
