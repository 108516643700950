import { FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { GridContainer, GridItem } from 'components/shared/ui/styles/Grid.styles';
import TextField from '@mui/material/TextField';
import { useTranslations } from 'components/shared/i18n';
import { TextAreaFieldProps } from 'components/shared/forms/FormFieldTypes';
import FormHelperText from '@mui/material/FormHelperText';
import { Caption, ehiTheme, Subtitle1 } from '@ehi/ui';

export const TextAreaField: FunctionComponent<TextAreaFieldProps> = ({ header, name, maxChars }) => {
  const { control, trigger } = useFormContext();
  const { t } = useTranslations();

  const calculateCharCount = (charLength: number) => {
    return charLength ? maxChars - charLength : maxChars;
  };

  return (
    <GridContainer>
      <GridItem sm={12} style={{ paddingTop: 0 }}>
        <Subtitle1 bold>{header}</Subtitle1>
      </GridItem>

      <GridItem sm={12}>
        <Controller
          control={control}
          name={name}
          render={({ field, fieldState }) => (
            <div style={{ marginBottom: '6rem' }}>
              <TextField
                {...field}
                fullWidth
                value={field.value || ''}
                id={name}
                placeholder={header}
                multiline
                rows={4}
                error={!!fieldState.error}
                inputProps={{
                  maxLength: maxChars,
                  'aria-label': field.name,
                  'data-testid': field.name,
                }}
                onBlur={async () => {
                  await trigger(name);
                }}
              />
              <FormHelperText
                style={{ padding: ehiTheme.spacing(0, 2), display: 'flex', justifyContent: 'space-between' }}>
                {fieldState.error?.message && (
                  <Caption data-testid='errorMessage' color={'#B00020'}>
                    {fieldState.error?.message}
                  </Caption>
                )}
                <Caption data-testid='remainingChars'>
                  {calculateCharCount(field.value?.length)}/{maxChars} {t('quickReservation.charactersLeft')}
                </Caption>
              </FormHelperText>
            </div>
          )}
        />
      </GridItem>
    </GridContainer>
  );
};
